import {Image, StyleSheet, Text, View} from 'react-native'
import {Button, Link, Stack} from '@mui/material'
import {
  Facebook as FacebookIcon,
  Group as GroupIcon,
  Instagram as InstagramIcon,
  LocationOn as LocationOnIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon
} from '@mui/icons-material'
import Constants from 'expo-constants'

export default function Home() {
  return (
    <View style={styles.container}>
      <Image style={styles.logo} resizeMode="contain" source={require("../assets/ellerton/logo.png")}/>
      <Text style={styles.title}>{Constants.manifest?.name}</Text>
      <Stack spacing={2} direction="column" maxWidth={800} width="100%">
        {Constants.manifest?.extra?.urls.YouTube && (
          <Button
            variant="outlined"
            component={Link}
            href={Constants.manifest?.extra?.urls.YouTube}
            startIcon={<YouTubeIcon/>}>
            YouTube
          </Button>
        )}
        {Constants.manifest?.extra?.urls.Facebook && (
          <Button
            variant="outlined"
            component={Link}
            href={Constants.manifest?.extra?.urls.Facebook}
            startIcon={<FacebookIcon/>}>
            Facebook
          </Button>
        )}
        {Constants.manifest?.extra?.urls.Instagram && (
          <Button
            variant="outlined"
            component={Link}
            href={Constants.manifest?.extra?.urls.Instagram}
            startIcon={<InstagramIcon/>}>
            Instagram
          </Button>
        )}
        {Constants.manifest?.extra?.urls.Twitter && (
          <Button
            variant="outlined"
            component={Link}
            href={Constants.manifest?.extra?.urls.Twitter}
            startIcon={<TwitterIcon/>}>
            Twitter
          </Button>
        )}
        {Constants.manifest?.extra?.urls.Registration && (
          <Button
            variant="outlined"
            component={Link}
            href={Constants.manifest?.extra?.urls.Registration}
            startIcon={<GroupIcon/>}>
            Become a member
          </Button>
        )}
        {Constants.manifest?.extra?.urls.GoogleMaps && (
          <Button
            variant="outlined"
            component={Link}
            href={Constants.manifest?.extra?.urls.GoogleMaps}
            startIcon={<LocationOnIcon/>}>
            Location
          </Button>
        )}
      </Stack>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#d0e6f1',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 64
  },
  logo: {
    height: 104,
    width: 104,
    borderRadius: 52,
    marginBottom: 12,
    borderWidth: 1,
    borderColor: "#e5e5e5",
    backgroundColor: "white"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 32,
  }
})
